/* eslint-disable react/require-default-props */
import React, { useCallback, useEffect, useState } from 'react';
import Image, { ImageLoaderProps, ImageProps } from "next/legacy/image";
import { omit } from 'ramda';
import config from 'common/config';

type NextImageType = ImageProps & { fallBackImageUrl?: string; type?: 'cover' | 'product' | 'thumbnail' };

const IMG_PARAMS = 'f=auto,metadata=none/';
const ZYDA_STATIC_URL = config.common.zydaStaticUrl;
const CDN_URL = config.common.imagesCDNUrl;
const defaultFallbackImageUrl = config.common.fallbackImageUrl;

const allowedSizes = {
  cover: [640, 1080, 1920],
  product: [640, 1080],
  thumbnail: [640, 1080],
};

const cloudflareLoader = ({ src, width, quality, type }: ImageLoaderProps & { type: 'cover' | 'product' | 'thumbnail' }) => {
  const sizes = allowedSizes[type] || [];
  const closestSize = sizes.reduce((prev, curr) => (Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev));
  const imgWidth = `width=${closestSize}`;
  const imgQuality = quality ? `quality=${quality}` : 'quality=75';
  const params = [imgWidth, imgQuality, IMG_PARAMS].filter(Boolean).join(',');
  const optimizedUrl = `${src.replace(ZYDA_STATIC_URL, `${CDN_URL}${params}`)}`;

  return optimizedUrl;
};

const NextImage: React.FC<NextImageType> = ({ src: imageSrc, fallBackImageUrl, type = 'product', ...rest }) => {
  const pickedNextImageProps = omit(['fallBackImageUrl', 'type'], rest);
  const [imgSrc, setImgSrc] = useState(imageSrc || fallBackImageUrl || defaultFallbackImageUrl);

  useEffect(() => {
    setImgSrc(imageSrc || fallBackImageUrl || defaultFallbackImageUrl);
  }, [imageSrc, fallBackImageUrl]);

  const setFallbackImage = useCallback(() => {
    setImgSrc(currentSrc => {
      if (currentSrc === imageSrc && !!fallBackImageUrl) return fallBackImageUrl;
      return defaultFallbackImageUrl;
    });
  }, [imageSrc, fallBackImageUrl]);

  const customLoader = (loaderProps: ImageLoaderProps) => cloudflareLoader({ ...loaderProps, type });

  return (
    <Image
      src={imgSrc}
      onError={setFallbackImage}
      {...(typeof imgSrc === 'string' && !!ZYDA_STATIC_URL && imgSrc?.includes(ZYDA_STATIC_URL) && { loader: customLoader })}
      {...pickedNextImageProps}
    />
  );
};
export default NextImage;
